var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      this.$route.params.uuid
        ? _c("div", { staticClass: "alert alert-warning" }, [_vm._m(0)])
        : _vm._e(),
      _c(
        "b-form",
        {
          ref: "formFilter",
          staticClass: "m-0 mt-1",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitCreate.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "col-md-8" },
              [
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { "no-body": "" } },
                  [
                    _c("b-card-header", [
                      _c(
                        "div",
                        [
                          _c("b-card-title", { staticClass: "mb-1" }, [
                            _vm._v(" Dados gerais "),
                          ]),
                          _c("b-card-sub-title", [
                            _vm._v(
                              " Os dados inseridos abaixo serão utilizados ao emitir a nota fiscal. "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c("b-card-body", { staticClass: "pl-2 pr-2" }, [
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col-md-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(" Tipo de documento "),
                              ]),
                              _c(
                                "v-select",
                                {
                                  attrs: {
                                    searchable: false,
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    options: _vm.optionsDocumentTypes,
                                  },
                                  model: {
                                    value: _vm.item.document_type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "document_type", $$v)
                                    },
                                    expression: "item.document_type",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-md-8" }, [
                          _c(
                            "div",
                            { staticClass: "form-group mb-md-0" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Fornecedor "),
                              ]),
                              _c(
                                "v-select",
                                {
                                  class: {
                                    "is-invalid": _vm.$v.item.supplier.$error,
                                  },
                                  attrs: {
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o nome",
                                    options: _vm.optionsSuppliers,
                                  },
                                  on: {
                                    search: _vm.fetchSupplier,
                                    input: _vm.supplierSelected,
                                  },
                                  model: {
                                    value: _vm.item.supplier,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "supplier", $$v)
                                    },
                                    expression: "item.supplier",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-md-3" }, [
                          _c("div", { staticClass: "form-group mb-md-0" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(" Nº Documento "),
                              _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.top",
                                    value:
                                      "Referenciar documento fora da Seducar.",
                                    expression:
                                      "\n                        'Referenciar documento fora da Seducar.'\n                      ",
                                    modifiers: { hover: true, top: true },
                                  },
                                ],
                                staticClass: "bi bi-question-circle",
                              }),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.document_number,
                                  expression: "item.document_number",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.document_number },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "document_number",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group mb-md-0" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Categoria "),
                              ]),
                              _c(
                                "v-select",
                                {
                                  attrs: {
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o nome",
                                    options: _vm.optionsCategories,
                                  },
                                  model: {
                                    value: _vm.item.category,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "category", $$v)
                                    },
                                    expression: "item.category",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      class: {
                                        "is-invalid":
                                          _vm.$v.item.category.$error,
                                      },
                                      attrs: { slot: "no-options" },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-md-5" }, [
                          _c(
                            "div",
                            { staticClass: "form-group mb-md-0" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Histórico PC "),
                              ]),
                              _c(
                                "v-select",
                                {
                                  class: {
                                    "is-invalid": _vm.$v.item.plan.$error,
                                  },
                                  attrs: {
                                    searchable: true,
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    options: _vm.optionsPlans,
                                  },
                                  model: {
                                    value: _vm.item.plan,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "plan", $$v)
                                    },
                                    expression: "item.plan",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { "no-body": "" } },
                  [
                    _c("b-card-header", [
                      _c(
                        "div",
                        [
                          _c("b-card-title", { staticClass: "mb-1" }, [
                            _vm._v("Total e Pagamentos "),
                          ]),
                          _c("b-card-sub-title", [
                            _vm._v(
                              " Abaixo serão listadas os próximos pagamentos. "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "b-card-body",
                      { staticClass: "pl-2 pr-2" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "form-row",
                            staticStyle: { "align-items": "center" },
                          },
                          [
                            _c("div", { staticClass: "col-md-3" }, [
                              _c(
                                "div",
                                { staticClass: "form-group mb-md-0" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _c("i", {
                                      staticClass:
                                        "text-danger bi bi-record-circle",
                                    }),
                                    _vm._v(" Total "),
                                  ]),
                                  _c(
                                    "money",
                                    _vm._b(
                                      {
                                        staticClass: "form-control",
                                        class: {
                                          "is-invalid":
                                            _vm.$v.item.total.$error,
                                        },
                                        nativeOn: {
                                          blur: function ($event) {
                                            return _vm.setTotal.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                          focus: function ($event) {
                                            return _vm.captureInitialValue(
                                              _vm.item
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.item.total,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "total", $$v)
                                          },
                                          expression: "item.total",
                                        },
                                      },
                                      "money",
                                      _vm.money,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "col-md-3" }, [
                              _c("div", { staticClass: "form-group mb-md-0" }, [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Pagamentos "),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: ["####"],
                                      expression: "['####']",
                                    },
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.item.installments,
                                      expression: "item.installments",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.item.installments.$error,
                                  },
                                  attrs: { type: "tel" },
                                  domProps: { value: _vm.item.installments },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.item,
                                        "installments",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-md-3" }, [
                              _c(
                                "div",
                                { staticClass: "form-group mb-md-0 mb-0" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _c("i", {
                                      staticClass:
                                        "text-danger bi bi-record-circle",
                                    }),
                                    _vm._v(" Vencimento "),
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover.top",
                                          value:
                                            "Escolha o dia para vencimento. Ex.: Todo dia 5 do mês.",
                                          expression:
                                            "\n                        'Escolha o dia para vencimento. Ex.: Todo dia 5 do mês.'\n                      ",
                                          modifiers: { hover: true, top: true },
                                        },
                                      ],
                                      staticClass: "bi bi-question-circle",
                                    }),
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.installment_due,
                                        expression: "item.installment_due",
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: ["##"],
                                        expression: "['##']",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: {
                                      "is-invalid":
                                        _vm.$v.item.installment_due.$error,
                                    },
                                    attrs: { max: "31", placeholder: "1 à 31" },
                                    domProps: {
                                      value: _vm.item.installment_due,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.item,
                                          "installment_due",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-md-3" }, [
                              _c("div", { staticClass: "form-group mb-0" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "invisible",
                                    attrs: { for: "" },
                                  },
                                  [_vm._v("a")]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-block btn-primary",
                                    attrs: {
                                      type: "button",
                                      disabled: !_vm.canGenerateInstallments,
                                    },
                                    on: { click: _vm.generateInstallments },
                                  },
                                  [_vm._v(" Gerar ")]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _vm.installments.length ? _c("hr") : _vm._e(),
                        _vm.installments.length
                          ? _c(
                              "div",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    attrs: { for: "due-date-picker" },
                                  },
                                  [
                                    _vm._v(
                                      "Se desejar, altere a data de vencimento de todas as parcelas para:"
                                    ),
                                  ]
                                ),
                                _c("flat-pickr", {
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "due-date-picker",
                                    placeholder:
                                      "Selecione uma data para todas as parcelas",
                                    config: {
                                      altInput: true,
                                      dateFormat: "Y-m-d",
                                      altFormat: "d/m/Y",
                                      locale: "pt",
                                    },
                                  },
                                  model: {
                                    value: _vm.selectedDueDate,
                                    callback: function ($$v) {
                                      _vm.selectedDueDate = $$v
                                    },
                                    expression: "selectedDueDate",
                                  },
                                }),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "btn btn-success w-100 mt-2",
                                    attrs: {
                                      type: "button",
                                      disabled: !_vm.selectedDueDate,
                                    },
                                    on: { click: _vm.updateInstallmentsDate },
                                  },
                                  [_vm._v(" Confirmar ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-card",
                          { attrs: { "no-body": "" } },
                          [
                            _vm.installments.length
                              ? _c("b-table", {
                                  staticClass: "position-relative",
                                  staticStyle: {
                                    transition: "background-color 0.5s ease",
                                  },
                                  attrs: {
                                    "sticky-header": "",
                                    items: _vm.installments,
                                    responsive: "",
                                    fields: _vm.installmentsTable,
                                    "show-empty": "",
                                    "empty-text": "Nenhum registro encontrado",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "cell(installment)",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return [
                                            _c(
                                              "strong",
                                              { staticClass: "relative" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.installment) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "cell(date)",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return [
                                            _c("flat-pickr", {
                                              staticClass: "form-control",
                                              attrs: {
                                                config: {
                                                  altInput: true,
                                                  dateFormat: "Y-m-d",
                                                  altFormat: "d/m/Y",
                                                  locale: "pt",
                                                },
                                              },
                                              model: {
                                                value: item.date,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "date", $$v)
                                                },
                                                expression: "item.date",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                      {
                                        key: "cell(total)",
                                        fn: function (ref) {
                                          var item = ref.item
                                          var index = ref.index
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "input-group mb-0",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-group-prepend",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "input-group-text",
                                                        attrs: {
                                                          id: "basic-addon1",
                                                        },
                                                      },
                                                      [_vm._v("R$")]
                                                    ),
                                                  ]
                                                ),
                                                _c("money", {
                                                  staticClass: "form-control",
                                                  nativeOn: {
                                                    blur: function ($event) {
                                                      return _vm.updateInstallments(
                                                        index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.total,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "total",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.total",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "cell(actions)",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-danger",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteInstallment()
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "bi bi-trash",
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1302573022
                                  ),
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "form-row mt-3 mb-2" }, [
                  _c("div", { staticClass: "col-md-11" }, [
                    _c("h4", { staticClass: "mb-0" }, [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                        staticStyle: { "font-size": "0.9rem" },
                      }),
                      _vm._v(" Rateio "),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "mb-0",
                        staticStyle: { "font-size": "0.9rem" },
                      },
                      [
                        _vm._v(
                          " Aqui, você cadastrará o rateio, selecionando a unidade de negócio e a estratégica. "
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-1" },
                    [
                      Number(_vm.item.total) > 0
                        ? _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "ripple",
                                  rawName: "v-ripple.400",
                                  value: "rgba(255, 255, 255, 0.15)",
                                  expression: "'rgba(255, 255, 255, 0.15)'",
                                  modifiers: { 400: true },
                                },
                              ],
                              staticClass: "btn-block",
                              attrs: {
                                disabled:
                                  _vm.item.strategic_units.length > 0
                                    ? true
                                    : false,
                                variant: "primary",
                              },
                              on: { click: _vm.addStrategicUnit },
                            },
                            [_c("i", { staticClass: "bi bi-plus" })]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._l(
                  _vm.item.strategic_units,
                  function (strategicElement, i) {
                    return _c(
                      "b-card",
                      {
                        key: "business-units-key-" + i,
                        staticClass: "mb-0 card-header-custom-actions mb-1",
                        attrs: { "header-tag": "header" },
                      },
                      [
                        _c("b-card-text", { staticClass: "mt-0" }, [
                          _c(
                            "div",
                            { staticClass: "form-row align-items-center" },
                            [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group mb-0" },
                                  [
                                    _c(
                                      "v-select",
                                      {
                                        class: {
                                          "is-invalid":
                                            _vm.$v.item.strategic_units.$each[i]
                                              .strategic_unit.$error,
                                        },
                                        attrs: {
                                          label: "title",
                                          "item-text": "title",
                                          "item-value": "code",
                                          placeholder: "Unidade estratégica",
                                          options: _vm.optionsStrategicUnits,
                                          clearable: false,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.strategicUnitSelected(
                                              i,
                                              strategicElement
                                            )
                                          },
                                          search: _vm.fetchStrategics,
                                        },
                                        model: {
                                          value:
                                            strategicElement.strategic_unit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              strategicElement,
                                              "strategic_unit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "strategicElement.strategic_unit",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "no-options" },
                                            slot: "no-options",
                                          },
                                          [
                                            _vm._v(
                                              " Nenhum registro encontrado "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-md-12" },
                                [
                                  _c(
                                    "b-card-text",
                                    { staticClass: "card-strategic p-1 mt-1" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-row",
                                          staticStyle: {
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-11" },
                                            [
                                              _c(
                                                "h5",
                                                { staticClass: "mb-0" },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "text-danger bi bi-record-circle",
                                                    staticStyle: {
                                                      "font-size": "0.9rem",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " Unidades de negócio "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-md-1" },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "ripple",
                                                      rawName: "v-ripple.400",
                                                      value:
                                                        "rgba(255, 255, 255, 0.15)",
                                                      expression:
                                                        "'rgba(255, 255, 255, 0.15)'",
                                                      modifiers: { 400: true },
                                                    },
                                                  ],
                                                  staticClass: "btn-block",
                                                  staticStyle: {
                                                    height: "33px",
                                                    padding: "0px !important",
                                                  },
                                                  attrs: { variant: "primary" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addBusinessUnit(
                                                        strategicElement
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "bi bi-plus",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._l(
                                        strategicElement.business,
                                        function (businessElement, index) {
                                          return _c(
                                            "div",
                                            {
                                              key:
                                                "business-strategic-key-" +
                                                index,
                                              staticClass:
                                                "form-row align-items-center mt-1",
                                              class: {
                                                "mb-1":
                                                  index !==
                                                  strategicElement.business
                                                    .length -
                                                    1,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-md-6" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group mb-0",
                                                    },
                                                    [
                                                      _c(
                                                        "v-select",
                                                        {
                                                          attrs: {
                                                            label: "title",
                                                            "item-text":
                                                              "title",
                                                            "item-value":
                                                              "code",
                                                            clearable: false,
                                                            placeholder:
                                                              "Unidade de negócio",
                                                            options:
                                                              strategicElement
                                                                .strategic_unit
                                                                .business,
                                                          },
                                                          on: {
                                                            search:
                                                              _vm.fetchBusinessUnits,
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.businessUnitSelected(
                                                                businessElement
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              businessElement.business_unit,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                businessElement,
                                                                "business_unit",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "businessElement.business_unit",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "no-options",
                                                              },
                                                              slot: "no-options",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Nenhum registro encontrado "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group mb-0",
                                                    },
                                                    [
                                                      _c(
                                                        "money",
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "form-control",
                                                            nativeOn: {
                                                              focus: function (
                                                                $event
                                                              ) {
                                                                return _vm.captureInitialPercent(
                                                                  businessElement
                                                                )
                                                              },
                                                              blur: function (
                                                                $event
                                                              ) {
                                                                return _vm.updateRateioBusinessUnitPercent(
                                                                  businessElement,
                                                                  strategicElement
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                businessElement.percent,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    businessElement,
                                                                    "percent",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "businessElement.percent",
                                                            },
                                                          },
                                                          "money",
                                                          _vm.percentFormat,
                                                          false
                                                        )
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-3" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group mb-0",
                                                    },
                                                    [
                                                      _c("money", {
                                                        staticClass:
                                                          "form-control",
                                                        nativeOn: {
                                                          focus: function (
                                                            $event
                                                          ) {
                                                            return _vm.captureInitialValue(
                                                              businessElement
                                                            )
                                                          },
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.updateRateioBusinessUnitTotal(
                                                              businessElement,
                                                              strategicElement
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            businessElement.total,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              businessElement,
                                                              "total",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "businessElement.total",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-1" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group mb-0",
                                                    },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "ripple",
                                                              rawName:
                                                                "v-ripple.400",
                                                              value:
                                                                "rgba(234, 84, 85, 0.15)",
                                                              expression:
                                                                "'rgba(234, 84, 85, 0.15)'",
                                                              modifiers: {
                                                                400: true,
                                                              },
                                                            },
                                                          ],
                                                          staticClass:
                                                            "btn-trash",
                                                          attrs: {
                                                            variant: "danger",
                                                            block: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeAtBusinessUnit(
                                                                index,
                                                                strategicElement
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "bi bi-trash3",
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _c("strategic-summary", {
                                    attrs: {
                                      strategicElement: strategicElement,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    )
                  }
                ),
                !_vm.item.strategic_units.length
                  ? _c(
                      "b-card",
                      [
                        _c("b-card-text", [
                          _vm._v("Selecione o "),
                          _c("strong", [_vm._v("Histórico PC")]),
                          _vm._v(" e gere os pagamentos."),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { "no-body": "" } },
                  [
                    _c("b-card-header", { staticClass: "mb-0" }, [
                      _c("div", [
                        _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-11" },
                            [
                              _c("b-card-title", { staticClass: "mb-1" }, [
                                _vm._v(" Arquivos "),
                              ]),
                              _c("b-card-sub-title", [
                                _vm._v(
                                  " Você pode adicionar 1 ou mais arquivos relacionado a este pedido de compra. "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-md-1" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-block btn-primary",
                                staticStyle: {
                                  "padding-left": "5px !important",
                                  "padding-right": "5px !important",
                                },
                                attrs: { type: "button" },
                                on: { click: _vm.selectFiles },
                              },
                              [_c("i", { staticClass: "bi bi-plus-circle" })]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("b-card-body", { staticClass: "p-2" }, [
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "form-group mb-0" }, [
                            _c("input", {
                              staticClass: "form-control-file hidden",
                              attrs: {
                                id: "files",
                                type: "file",
                                multiple: "",
                                accept:
                                  ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf",
                              },
                              on: { change: _vm.previewDocs },
                            }),
                            !_vm.item.files.length
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "py-3 text-center mb-0",
                                    staticStyle: {
                                      border: "1px solid rgb(222 222 222)",
                                      "border-radius": "4px",
                                      "border-style": "dashed",
                                      "font-size": "1.1rem",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi bi-file-earmark-text",
                                    }),
                                    _vm._v(" Selecionar arquivos "),
                                    _c(
                                      "small",
                                      {
                                        staticClass: "text-center mt-1 d-block",
                                      },
                                      [
                                        _vm._v(
                                          " Selcione apenas arquivos nos formatos: "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " xlsx, xls, imagem, doc, docx, ppt, pptx, txt e pdf "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "label",
                              { staticClass: "d-block text-center mb-0" },
                              [
                                _vm.item.files.length
                                  ? _c(
                                      "ul",
                                      {
                                        staticClass:
                                          "list-group list-group-light",
                                        attrs: { id: "array-files" },
                                      },
                                      _vm._l(
                                        _vm.item.files,
                                        function (doc, index) {
                                          return _c(
                                            "li",
                                            {
                                              key: index,
                                              staticClass:
                                                "list-group-item border-0 rounded-5 mb-5-px text-left px-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-md-9 text-left",
                                                      staticStyle: {
                                                        "font-size": "0.9rem",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Arquivo " +
                                                          _vm._s(index + 1) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-md-2 text-center",
                                                      staticStyle: {
                                                        "font-size": "0.9rem",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f("bytesToMB")(
                                                              doc.size,
                                                              2
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-md-1 text-center",
                                                      staticStyle: {
                                                        "font-size": "0.9rem",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-danger btn-sm",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "bi bi-trash3",
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { "no-body": "" } },
                  [
                    _c("b-card-header", [
                      _c(
                        "div",
                        [
                          _c("b-card-title", { staticClass: "mb-0" }, [
                            _vm._v(" Detalhes "),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c("b-card-body", { staticClass: "pl-2 pr-2" }, [
                      _c("div", { staticClass: "form-group mb-0" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Histórico"),
                        ]),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.description,
                              expression: "item.description",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { name: "", rows: "3" },
                          domProps: { value: _vm.item.description },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "description",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { "no-body": "" } },
                  [
                    _c("b-card-header", [
                      _c(
                        "div",
                        [
                          _c("b-card-title", { staticClass: "mb-1" }, [
                            _vm._v(" Tags "),
                          ]),
                          _c("b-card-sub-title", [
                            _vm._v(" A tag ajudará você a pesquisar "),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "b-card-body",
                      { staticClass: "pl-2 pr-2" },
                      [
                        _c("b-form-tags", {
                          staticClass: "form-control mb-1",
                          attrs: {
                            separator: ",;",
                            "no-add-on-enter": "",
                            placeholder: "Escreva aqui...",
                            rows: "3",
                          },
                          model: {
                            value: _vm.item.tags,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "tags", $$v)
                            },
                            expression: "item.tags",
                          },
                        }),
                        _c("small", [
                          _vm._v("Separe os termos usando vírgula."),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("BtnSaveAndBack", {
                  attrs: {
                    disabled: !_vm.canSave,
                    routerBack: "orders-purchases-list",
                    variant: "success",
                    submited: _vm.submited,
                    text: "Cadastrar",
                    subtext: "Aguarde...",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alert-body" }, [
      _c("h6", { staticClass: "text-warning mb-0" }, [
        _vm._v(" Você optou por duplicar o pedido de compra. "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }